body {
	text-align: center;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
	background-image: url("./images/github.png");
	background-size: contain;
    background-repeat: repeat;
    background-attachment: fixed;
    background-position: center;
	margin: 0;
    z-index: -2;
    font-family: 'Comfortaa', cursive;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
