.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 2;
  margin: 0;
}

.me {
  object-fit: cover;
	border-style: solid;
	border-color: blue; 
	border-radius: 100px;
	height: 140px;
	width: 140px;
}
