.nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  width: 70vw;
  height: 10vh;
  align-items: center;
  margin: 5px;
}
.nav > a {
  font-family: 'Merriweather', serif;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size-adjust: inherit;
  text-decoration: none;
  height: inherit;
  cursor: pointer;
  width: inherit;
  border-radius: 10px;
  border-left: solid gray 2px;
}
.nav > a:hover {
  background-color: rgb(255, 255, 255);
}
a:visited {
  color: black;
}
@media only screen and (max-width: 500px){
  .nav{
    font-size: 10px;
    font-weight: bold;
  }
}
