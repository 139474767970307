p{
	margin: 10px;
}
.projects{
	display: flex;
	flex-wrap: wrap;
    justify-content: space-around;
}
.exampleFront, .exampleBack{
	
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	border-radius: 20px;
	backface-visibility: hidden;
	position: absolute;
	width: 100%;
	height: 100%;
}
.exampleLink{
	padding: 10px;
	background-color: #c6e48b;
	border-radius: 10px;
}
.exampleLink:hover{
	background-color: #7bc96f;
}
.exampleFront{
	background-color: rgba(255, 255, 255, 0.8);
}
.exampleBack{
	background-color: rgba(255, 255, 255, 0.8);
	transform: rotateY(180deg);
}


.flipContain{
	background-color: transparent;
	margin: 5px;
	width: 350px;
	height: 400px;
	perspective: 1000px;
}
.flipInner{
	display: flex;
	justify-content: center;
	position: relative;
	width: 100%;
	height: 100%;
	text-align: center;
	transition: transform 1s;
	transform-style: preserve-3d;
}
.flipContain:hover .flipInner{
	transform: rotateY(180deg);
}
.exImg{
	border-radius: 20px;
	border: solid rgb(63, 0, 235) 2px;
	width: 200px;
	height: 200px;
	background-size: cover;
	background-position: center;
    background-repeat: no-repeat;
}
.uselist{
	border: solid rgb(63, 0, 235) 2px;
	border-radius: 5px;
	display: grid;
	grid-template-rows: repeat(3, auto);
	grid-template-columns: repeat(3, auto);
	grid-column-gap: 5px;
	grid-area: auto;
	padding: 5px;
	margin: 5px;
}

a{
	color: black;
	text-decoration: none;
}
a:visited{
	color: black;
}
.projContain{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}